import React from "react";

const About = () => {
  return (
    <>
      {/* Start About */}
      <section id="about">
        <div className="cs-height_100 cs-height_lg_70" />
        <div className="container">
          <div className="row align-items-center flex-column-reverse-lg">
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="cs-height_0 cs-height_lg_40" />
              <div className="cs-right_space_40">
                <div className="cs-seciton_heading cs-style1">
                  <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color text-uppercase">
                    Our Story
                  </h3>
                  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">
                    About Us
                  </h2>
                </div>
                <div className="cs-height_20 cs-height_lg_20" />
                <p
                  style={{
                    fontSize: "25px",
                    letterSpacing: "2px",
                    lineHeight: "35px",
                  }}
                >
                  ERUSD Is A Cutting-Edge Algorithmic Stable Coin Built On The
                  Ethereum Classic (ETC)
                </p>
                <p>
                  Designed To Offer Unparalleled Stability And Reliability In
                  The Fast-Paced World Of Cryptocurrencies. Unlike Traditional
                  Stablecoins, ERUSD Utilizes An Innovative Algorithmic
                  Mechanism To Maintain Its Value Pegged To The US Dollar
                  Without The Need For Direct Collateralization. This Unique
                  Approach Ensures Robustness And Transparency, Making ERUSD A
                  Standout Player In The Stablecoin Market.
                </p>
                <div className="cs-height_25 cs-height_lg_15" />
                <div className="cs-btn_group">
                  <a
                    href="#mint"
                    className="cs-btn cs-btn_filed cs-accent_btn cs-smoth_scroll"
                  >
                    <span>Mint NFT</span>
                  </a>
                  {/* <a href="#" className="cs-btn cs-btn cs-color1">
                    <span>Whitelist Now</span>
                  </a> */}
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <img src="assets/img/about_img.svg" alt="about" />
            </div>
          </div>
        </div>
      </section>
      {/* End About */}
    </>
  );
};

export default About;
