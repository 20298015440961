import ABI from "../ABI.json";
import Web3 from "web3";
import { getEvents } from "../utils/event";

const { ethereum } = window;
const web3 = new Web3(ethereum);
const contractAddress = "0x195A48474C05F56584EA13Cb33850703dD0f4188";
const ABI_VALUE = ABI;
const contract = new web3.eth.Contract(ABI_VALUE, contractAddress);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const status = await checkChainID();
      if (status === true) {
        const obj = {
          res: addressArray[0],
          status: true,
        };
        return obj;
      } else {
        const obj = {
          res: "Please Connect to Georli",
          status: false,
        };
        return obj;
      }
    } catch (err) {
      return {
        res: "User Rejected Request",
        false: false,
      };
    }
  }
};

const checkChainID = async () => {
  const chainid = await web3.eth.net.getId();
  console.log(chainid);
  if (chainid === 1) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      const status = await checkChainID();
      if (status === true) {
        return {
          res: addressArray[0],
          status: true,
        };
      } else {
        return {
          res: "Please Connect to Goerli",
          status: false,
        };
      }
    } catch (err) {
      return {
        res: "",
        status: false,
      };
    }
  }
};

function generateUniqueRandomNumbers(existingArray, count, min, max) {
  const result = [];

  while (result.length < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    if (
      !existingArray.includes(String(randomNumber)) &&
      !result.includes(String(randomNumber)) &&
      !result.includes(randomNumber)
    ) {
      result.push(randomNumber);
    }
  }

  return result;
}

export const mint = async (minQnt, tsupply) => {
  if (window.ethereum) {
    const status = await checkChainID();
    if (status === true) {
      try {
        const accounts = await web3.eth.getAccounts();
        // 1000000000000000000;
        let ETH_COST = 500000000000000000;
        // Number(tsupply) <= 500
        //   ? 1350000000000000000
        //   : Number(tsupply) > 500 && Number(tsupply) <= 1500
        //   ? 1350000000000000000
        //   : Number(tsupply) > 1500 && Number(tsupply) <= 2000
        //   ? 1750000000000000000
        //   : Number(tsupply) > 2000 && Number(tsupply) <= 3000
        //   ? 2250000000000000000
        //   : Number(tsupply) > 3000 && Number(tsupply) <= 4000
        //   ? 2750000000000000000
        //   : Number(tsupply) > 4000 && Number(tsupply) <= 5000
        //   ? 3000000000000000000
        //   : Number(tsupply) > 5000 && Number(tsupply) <= 5700
        //   ? 3500000000000000000
        //   : Number(tsupply) > 5700 && Number(tsupply) <= 6500
        //   ? 3750000000000000000
        //   : Number(tsupply) > 6500 && Number(tsupply) <= 7000
        //   ? 3800000000000000000
        //   : 3800000000000000000;

        if (Number(tsupply) <= 7000) {
          let totalCostWei = String(ETH_COST * minQnt);
          const minted = await getEvents();
          const idArray = minted.map((obj) => obj.tokenId);
          const generatedNumbers = generateUniqueRandomNumbers(
            idArray,
            minQnt,
            1,
            7000
          );
          console.log(generatedNumbers);
          const response = await contract.methods.mint(generatedNumbers).send({
            from: accounts[0],
            value: totalCostWei,
          });
          console.log(response);
          return {
            success: true,
            response,
          };
        }
      } catch (error) {
        console.log(error);
        return {
          success: false,
          response: "😥 Mint gone wrong: " + error.message,
        };
      }
    }
  }
};

export const TransferNFTCall = async (to, token) => {
  //error handling
  // if (to === "" || token === "") {
  //   return {
  //     success: false,
  //     status: "❗Please make sure all fields are completed before minting.",
  //   };
  // }
  const accounts = await web3.eth.getAccounts();
  try {
    await contract.methods
      .transferFrom(accounts[0], to, token)
      .send({
        from: accounts[0],
      })
      .then(function (result) {
        // console.log(result);
      });
    return {
      success: true,
      status: "🙌 NFT Successfully Transfered",
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const walletOfOwner = async () => {
  if (window.ethereum) {
    const accounts = await web3.eth.getAccounts();

    try {
      const token = await contract.methods.walletOfOwner(accounts[0]).call();
      return token;
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};

export const OwnerOf = async (token) => {
  if (window.ethereum) {
    // const accounts = await web3.eth.getAccounts();

    try {
      const address = await contract.methods.ownerOf(token).call();
      return address;
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};

export const totalSupply = async () => {
  if (window.ethereum) {
    try {
      const token = await contract.methods.totalSupplys().call();
      return token;
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};
