import React from "react";
import About from "../components/About";
import Causes from "../components/Causes";
import Minter from "../components/Minter/Minter";
import Goals from "../components/Goals";
function Index() {
  return (
    <>
      {/* <Hero /> */}
      <About />
      <div className="cs-height_100 cs-height_lg_70"></div>
      <Causes />
      <div className="cs-height_70 cs-height_lg_45"></div>
      <Minter />
      {/* <Team /> */}
      <Goals />
    </>
  );
}

export default Index;
