import React, { useEffect, useState } from "react";
import { getEvents } from "../utils/event";
import { OwnerOf } from "../utils/Interact";

const PaginatedTable = ({ itemsPerPage, data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Wallet Address</th>
            <th>Token ID</th>
            <th>Image</th>
            <th>Category</th>
            <th>Traits</th>
            <th>Benefit</th>
            {/* Add more table headers if needed */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, key) => (
            <tr key={key}>
              <td>{item.to}</td>
              <td>{item.tokenId}</td>
              <td>
                <img
                  src={item.attribute.image}
                  alt=""
                  width={100}
                  height={100}
                />
              </td>
              <td>{item.attribute.attributes[0].value}</td>
              <td>{item.attribute.attributes[1].value}</td>
              <td>{item.attribute.attributes[2].value}</td>
              {/* Add more table cells based on your data */}
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        {/* Pagination buttons */}
        {data.length > itemsPerPage && (
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "90px",
            }}
          >
            {Array(Math.ceil(data.length / itemsPerPage))
              .fill()
              .map((_, index) => (
                <li
                  key={index}
                  style={{
                    listStyle: "none",
                    padding: "5px",
                  }}
                >
                  <button
                    onClick={() => paginate(index + 1)}
                    style={{
                      width: "50px",
                      border: "none",
                      color: "#111",
                    }}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

function Minted() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState([]);

  useEffect(() => {
    const getMinted = async () => {
      const list = [];
      const minted = await getEvents();
      minted.map(async (item) => {
        const wallet = await OwnerOf(item.tokenId);
        list.push({
          walletaddress: wallet,
          tokenId: item.tokenId,
        });
      });
      console.log(list);
      setData(minted);
    };

    getMinted();
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        overflowy: "scroll",
      }}
    >
      {data.length !== 0 ? (
        <>
          {/* {data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.to}</td>
                  <td>{item.tokenId}</td>
                  <td>
                    <img
                      src={item.attribute.image}
                      alt=""
                      width={100}
                      height={100}
                    />
                  </td>
                  <td>{item.attribute.attributes[0].value}</td>
                  <td>{item.attribute.attributes[1].value}</td>
                  <td>{item.attribute.attributes[2].value}</td>
                </tr>
              );
            })} */}
          <PaginatedTable itemsPerPage={50} data={data} />
        </>
      ) : (
        <>No Minted NFT Yet</>
      )}
    </div>
  );
}

export default Minted;
