import React, { useState } from "react";
import { connectWallet } from "../utils/Interact";
import { Link } from "react-router-dom";

const Header = () => {
  const [connectionSatatus, setConnectionStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [errorMsg, setErrorMssg] = useState("");

  const connectWalletPressed = async () => {
    const response = await connectWallet();
    console.log(response);
    if (response?.status) {
      // window.location.reload();
      setConnectionStatus(true);
      setWallet(response?.res);
    } else {
      setConnectionStatus(false);
      setErrorMssg(response?.res);
    }
  };
  return (
    <header
      className="cs-site_header cs-style1 cs-sticky-header cs-primary_color"
      id="header"
    >
      <div className="cs-main_header">
        <div className="container">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <Link to="/" className="cs-site_branding cs-accent_color">
                <img
                  src="https://www.veritasecosystem.com/wp-content/uploads/2023/08/Group-1.png"
                  alt="Logo"
                  className="cs-hide_dark"
                />
                <img
                  src="https://www.veritasecosystem.com/wp-content/uploads/2023/08/Group-1.png"
                  alt="Logo"
                  className="cs-hide_white"
                />
              </Link>
            </div>
            <div className="cs-main_header_center">
              <div className="cs-nav">
                <ul className="cs-nav_list">
                  <li className="menu-item-has-children">
                    <Link to="/" className="cs-smoth_scroll">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/#about" className="cs-smoth_scroll">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/#roadmap" className="cs-smoth_scroll">
                      Use Case
                    </Link>
                  </li>
                  {/* <li>
                    <a href="#team" className="cs-smoth_scroll">
                      Team
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="#faq" className="cs-smoth_scroll">
                      FAQ
                    </a>
                  </li> */}
                  <li>
                    <Link to="/#mint" className="cs-smoth_scroll">
                      Mint
                    </Link>
                  </li>

                  <li>
                    <Link to="/gallery" className="cs-smoth_scroll">
                      My Gallery
                    </Link>
                  </li>

                  <li>
                    <Link to="/top-nft" className="cs-smoth_scroll">
                      Top NFTs
                    </Link>
                  </li>
                  {/* <li className="menu-item-has-children">
                    <a href="#">Pages</a>
                    <ul>
                      <li>
                        <a href="blog.html">Blog Page</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="cs-main_header_right">
              <div className="cs-toolbox">
                <span
                  className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn"
                  data-modal="connect_wallet"
                  onClick={connectWalletPressed}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    className="iconify iconify--ion"
                    width="1em"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <rect
                      width="416"
                      height="288"
                      x="48"
                      y="144"
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      rx="48"
                      ry="48"
                    ></rect>
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M411.36 144v-30A50 50 0 0 0 352 64.9L88.64 109.85A50 50 0 0 0 48 159v49"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M368 320a32 32 0 1 1 32-32a32 32 0 0 1-32 32Z"
                    ></path>
                  </svg>
                  <span>
                    {connectionSatatus === false ? (
                      <>
                        {/* {walletAddress === "" ||
                        walletAddress === undefined ||
                        walletAddress === null ? ( */}
                        Connect Wallet
                        {/* ) : (
                          <>
                            {String(walletAddress).substring(0, 6) +
                              "..." +
                              String(walletAddress).substring(38)}
                          </>
                        )} */}
                      </>
                    ) : (
                      <>
                        <Link to="/gallery">
                          {String(walletAddress).substring(0, 6) +
                            "..." +
                            String(walletAddress).substring(38)}
                        </Link>
                      </>
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
