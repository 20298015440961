import Header from "./components/Header";
import Footer from "./components/Footer";
import Gallery from "./page/Gallery";
import Minted from "./page/Minted";
import Index from "./page/Index";
import Top from "./page/Top";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <div>
        <Header />
        <div className="cs-height_80 cs-height_lg_80"></div>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/minted" element={<Minted />} />
          <Route path="/top-nft" element={<Top />} />
        </Routes>
        {/* <Faq /> */}
        {/* <Contact />  */}
        {/* </>
        ) : (
          <>
            <Gallery />
          </>
        )} */}

        <div className="cs-height_100 cs-height_lg_70"></div>
        <Footer />
      </div>
    </>
  );
}

export default App;
