import React from "react";

const Goals = () => {
  return (
    <>
      {/* Start Goals */}
      <section id="roadmap">
        <div className="cs-height_70 cs-height_lg_40" />
        <div className="container">
          <div
            className="cs-seciton_heading cs-style1 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">
              Our Goals
            </h3>
            <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">
              Use Cases
            </h2>
          </div>
          <div className="cs-height_50 cs-height_lg_30" />
          <div
            className="cs-accent_seperator_1 cs-accent_color wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          />
          <div
            className="row wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            <div className="col-lg-4 col-md-6">
              <div className="cs-card cs-style2">
                <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
                  1
                  <div className="cs-card_tick">
                    <svg
                      width={18}
                      height={19}
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z"
                        fill="#06182C"
                        stroke="#0A2540"
                        strokeWidth={2}
                      />
                      <path
                        d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_15" />
                <div className="cs-card_in cs-light_bg">
                  <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">
                    Use Case 1
                  </h3>
                  <div className="cs-height_5 cs-height_lg_5" />
                  <h2 className="cs-font_24 cs-font_16_sm cs-m0">
                    Everyday Transactions
                  </h2>
                  <div className="cs-height_10 cs-height_lg_10" />
                  <p className="cs-m0">
                    Utilize ERUSD For Everyday Transactions, Avoiding The
                    Volatility Often Associated With Cryptocurrencies
                  </p>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25" />
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="cs-card cs-style2">
                <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
                  2
                  <div className="cs-card_tick">
                    <svg
                      width={18}
                      height={19}
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z"
                        fill="#06182C"
                        stroke="#0A2540"
                        strokeWidth={2}
                      />
                      <path
                        d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_15" />
                <div className="cs-card_in cs-light_bg">
                  <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">
                    Use Case 2
                  </h3>
                  <div className="cs-height_5 cs-height_lg_5" />
                  <h2 className="cs-font_24 cs-font_16_sm cs-m0">
                    Everyday Transactions
                  </h2>
                  <div className="cs-height_10 cs-height_lg_10" />
                  <p className="cs-m0">
                    Engage In Decentralized Finance Protocols, Earning Rewards
                    And Maximizing Yield Opportunities With A Stable And
                    Versatile Asset.
                  </p>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25" />
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="cs-card cs-style2">
                <div className="cs-card_number cs-accent_bg cs-white_color cs-center cs-primary_font cs-font_22 cs-bold">
                  3
                  <div className="cs-card_tick">
                    <svg
                      width={18}
                      height={19}
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.899994 9.90957C0.899994 5.43606 4.52649 1.80957 8.99999 1.80957C13.4735 1.80957 17.1 5.43606 17.1 9.90957C17.1 14.3831 13.4735 18.0096 8.99999 18.0096C4.52649 18.0096 0.899994 14.3831 0.899994 9.90957Z"
                        fill="#06182C"
                        stroke="#0A2540"
                        strokeWidth={2}
                      />
                      <path
                        d="M8.99999 1.80957C4.52649 1.80957 0.899994 5.43606 0.899994 9.90957C0.899994 14.3831 4.52649 18.0096 8.99999 18.0096C13.4735 18.0096 17.1 14.3831 17.1 9.90957C17.1 5.43606 13.4735 1.80957 8.99999 1.80957ZM12.4755 5.60348L14.1584 7.28636L8.90211 12.5437L7.2291 14.2157L5.54622 12.5328L3.84158 10.8271L5.51359 9.15512L7.21824 10.8608L12.4755 5.60348Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_15" />
                <div className="cs-card_in cs-light_bg">
                  <h3 className="cs-gradient_color cs-font_18 cs-font_16_sm cs-m0">
                    Use Case 3
                  </h3>
                  <div className="cs-height_5 cs-height_lg_5" />
                  <h2 className="cs-font_24 cs-font_16_sm cs-m0">
                    Cross-Border Payments
                  </h2>
                  <div className="cs-height_10 cs-height_lg_10" />
                  <p className="cs-m0">
                    Facilitate Cross-Border Payments With Ease And Speed,
                    Leveraging ERUSD’s Interoperability To Transact Across
                    Different Blockchain Networks.
                  </p>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25" />
            </div>
          </div>
          <a
            href="https://veritasecosystem.com"
            className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn"
            target="__blank"
          >
            <span>Visit Veritasecosystem.com</span>
          </a>
        </div>
      </section>
      {/* End Goals */}
    </>
  );
};

export default Goals;
