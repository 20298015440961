import React, { useState, useEffect } from "react";
import "./new.css";
import {
  mint,
  totalSupply,
  getCurrentWalletConnected,
} from "../../utils/Interact";

function Minter() {
  const [counter, setCounter] = useState(1);
  const [loading, setLoader] = useState(false);
  const [tsupply, setTotalSupply] = useState(false);

  const Subtract = () => {
    if (counter > 1) {
      setCounter(parseInt(counter) - 1);
    }
  };

  const Add = () => {
    if (counter < 20) {
      setCounter(parseInt(counter) + 1);
    }
  };
  const mintNft = async (mntQnt) => {
    setLoader(true);
    if (mntQnt <= 20) {
      const wallet = await getCurrentWalletConnected();
      console.log(wallet);
      if (wallet !== undefined) await mint(mntQnt, tsupply);
    }
    setLoader(false);
  };

  useEffect(() => {
    const getSupply = async () => {
      const totalSupply_ = await totalSupply();
      if (totalSupply_.success !== false) {
        setTotalSupply(totalSupply_);
      }
    };
    getSupply();
  }, []);

  return (
    <div className="minter_container" id="mint">
      <h2 style={{ textAlign: "center", color: "#E7E6E8", fontSize: "35px" }}>
        Mint NFTs
      </h2>

      <div className="mint_section">
        <br />
        <div className="mint_value" style={{ color: "#E7E6E8" }}>
          <h3 style={{ color: "#E7E6E8", fontSize: "17px" }}>
            Mint 20 NFTs Per Wallet Max
          </h3>
          <p style={{ color: "#E7E6E8" }}>
            {tsupply} Minted out of 7000 Collection
          </p>
          <h5 style={{ color: "#E7E6E8", fontSize: "14px" }}>
            {" "}
            NFT Price ={" 0.5"}
            {/* {Number(tsupply) <= 500
              ? "0.85"
              : Number(tsupply) > 500 && Number(tsupply) <= 1500
              ? "1.35"
              : Number(tsupply) > 1500 && Number(tsupply) <= 2000
              ? "1.75"
              : Number(tsupply) > 2000 && Number(tsupply) <= 3000
              ? "2.25"
              : Number(tsupply) > 3000 && Number(tsupply) <= 4000
              ? "2.75"
              : Number(tsupply) > 4000 && Number(tsupply) <= 5000
              ? "3"
              : Number(tsupply) > 5000 && Number(tsupply) <= 5700
              ? "3.5"
              : Number(tsupply) > 5700 && Number(tsupply) <= 6500
              ? "3.75"
              : Number(tsupply) > 6500 && Number(tsupply) <= 7000
              ? "3.8"
              : "3.8"}{" "} */}
            ETC + Gas Fee
          </h5>
        </div>
        <br />
        <div className="mint_box">
          <div className="mint" style={{ width: "40%" }}>
            <button
              onClick={() => Subtract()}
              style={{ border: "1px solid transparent", cursor: "pointer" }}
            >
              {" "}
              -{" "}
            </button>
            <span> {counter}</span>
            <button
              onClick={() => Add()}
              style={{ border: "1px solid transparent", cursor: "pointer" }}
            >
              {" "}
              +{" "}
            </button>
          </div>

          <button
            className="circle_button"
            style={{
              cursor: "pointer",
            }}
            onClick={() => setCounter(20)}
          >
            20
          </button>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            className="cs-btn_group"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading === false ? (
              <div
                href="#"
                className="cs-btn cs-btn_filed cs-accent_btn"
                // style={{ width: "60%" }}
                onClick={() => mintNft(counter)}
              >
                <span>Mint Now</span>
              </div>
            ) : (
              <>Processing..............</>
            )}
          </div>

          {/* <div
            style={{
              display: "flex",
              color: "#fff",
              fontSize: "2rem",
              gap: "1.7rem",
              marginTop: "2rem",
            }}
          >
            <div>
              {day} <br />{" "}
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Days
              </span>
            </div>
            <div>
              {hour} <br />{" "}
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Hours
              </span>
            </div>
            <div>
              {minute} <br />{" "}
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Minutes
              </span>
            </div>
            <div>
              {seconds} <br />{" "}
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Seconds
              </span>
            </div>
          </div> */}
        </div>
        <h5
          style={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "2rem",
          }}
        >
          Please use Chrome/Firefox with metamask extension for PC or metamask
          app for mobile
        </h5>
        <h3 className="contract">
          <a
            style={{
              textAlign: "center",
              fontSize: "14px",
              cursor: "pointer",
            }}
            target="__blank"
            href="https://etc.blockscout.com/address/0x195A48474C05F56584EA13Cb33850703dD0f4188"
          >
            View Contract
          </a>
        </h3>
      </div>
    </div>
  );
}

export default Minter;
